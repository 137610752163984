import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#505ddc",
                // primary: "#3f51b5",
                // secondary: "#b0bec5",
                // accent: "#8c9eff",
                error: "#EB5772",
                // success: "#4caf50",
            }
        }
    }
});
