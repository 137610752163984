<template>
  <v-container>
    <v-row class="align-center" no-gutters>
      <v-file-input
        label="File input"
        outlined
        dense
        hide-details
        v-model="file"
      ></v-file-input>
      <v-btn text class="ml-3" @click="uploadFile()">Upload</v-btn>
    </v-row>

    <!-- <div v-if="fileUrl" class="mt-5">
      <a :href="fileUrl">{{ fileUrl }}</a>
      <v-btn small text @click="deleteFile()">Delete File</v-btn>
    </div> -->

    <div class="mt-2" v-for="(item, i) in fileItems" :key="i">
      {{ i + 1 }}. <a :href="item.url" target="_blank">{{ item._location.path_ }}</a>
      <v-btn small text @click="deleteFile(item.name)">Delete File</v-btn>
    </div>
  </v-container>
</template>

<script>
import {
  uploadFile,
  getLink,
  deleteFile,
  listFile,
} from "@/service/storage.js";

export default {
  data: () => ({
    file: null,
    fileUrl: "",
    fileName: "",

    fileItems: [],
  }),

  async mounted() {
    await this.loadFiles();
  },

  methods: {
    async loadFiles() {
      const res = await listFile();
      this.fileItems = await Promise.all(
        res.items.map(async item => {
          item.url = await getLink(item.name);
          return item;
        })
      );
    },

    async uploadFile() {
      let res = await uploadFile(this.file);
      // this.fileName = res.fileName;
			// this.fileUrl = await getLink(this.fileName);
      this.loadFiles();
    },

    async deleteFile(name) {
      if (!name) name = this.fileName;
      const success = await deleteFile(name);
      if (success) {
        this.fileUrl = "";
        this.fileName = "";
        await this.loadFiles();
      }
    },

    getLink,
  },
};
</script>

<style>
</style>