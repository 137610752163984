<template>
  <v-container>
    <!-- Search Group -->
    <template v-if="!group.name">
      <content-card class="py-2">
        <v-row class="align-center" no-gutters>
          <v-text-field
            class="mr-3"
            :label="$t('home.groupId')"
            v-model="groupId"
            :persistent-hint="isGroupNotFound"
            :hint="isGroupNotFound ? $t('gen.notfound') : ''"
            @keydown.enter="searchGroup"
          ></v-text-field>
          <v-btn outlined @click="searchGroup">{{ $t("home.go") }}</v-btn>
        </v-row>
      </content-card>

      <DiscontinueCard v-if="false" />

      <!-- Search Group History -->
      <template v-if="!!groupsHistory.length">
        <div class="mt-5 font-weight-bold">{{ $t("home.searchHistory") }}</div>
        <div v-for="(group, i) in groupsHistory" :key="i">
          {{ i + 1 }}. {{ group.groupName }}
          <router-link :to="`/?id=${group.groupId}`">
            #{{ group.groupId }}
          </router-link>
          <v-icon small right @click="delGroupSearchHistory()"
            >mdi-trash-can</v-icon
          >
        </div>
      </template>

      <v-btn color="primary" to="/create" fixed large right bottom>
        <v-icon left>mdi-plus</v-icon>
        <span class="mr-2">{{ $t("gen.group") }}</span>
      </v-btn>
    </template>

    <!-- Group -->
    <GroupView
      v-else
      :group="group"
      @reloadTransactions="reloadTransactions()"
      @reloadGroup="reloadGroup()"
      @delGroupSearchHistory="delGroupSearchHistory"
    />
  </v-container>
</template>

<script>
import GroupView from "@/views/GroupView.vue";
import ContentCard from "@/components/ContentCard.vue";
import DiscontinueCard from "@/components/DiscontinueCard.vue";

import Group from "@/service/group.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Home",

  components: { GroupView, DiscontinueCard, ContentCard },

  data: () => ({
    user: "",
    groupId: "",
    group: {},
    isGroupNotFound: false,
    groupsHistory: [],
  }),

  mounted() {
    this.getGroupSearchHistory();

    this.user = this.$route.query.name;
    let groupId = this.$route.query.id;
    if (groupId) {
      this.groupId = groupId;
      this.searchGroup();
    }
  },

  methods: {
    async searchGroup() {
      if (!this.groupId) return (this.isGroupNotFound = true);
      let group = new Group(this.groupId.trim());
      this.group = group;
      this.isGroupNotFound = false;

      this.appIsLoading();
      await group.getInfo();
      this.appIsLoaded();

      if (!group.name) {
        this.delGroupSearchHistory();
        this.isGroupNotFound = true;
        return;
      }

      if (!this.$route.query.id)
        this.$router.replace({
          query: { ...this.$route.query, id: this.groupId },
        });

      this.saveGroupSearchHistory(this.groupId, group.name);
      group.sortMembers();
      this.reloadTransactions();
    },

    async reloadTransactions() {
      this.appIsLoading();
      await this.group.getTransactions();
      this.appIsLoaded();
      this.group.computeSummary();
      this.group.computeRentersRawCostEachTr();
      this.group.sortRentersAndPayersEachTransaction();
    },

    async reloadGroup() {
      this.appIsLoading();
      const group = this.group;
      await new Promise(r => setTimeout(r, 200))
      await group.getInfo();
      this.appIsLoaded();
    },

    // Search History Functions
    saveGroupSearchHistory(groupId, groupName) {
      let group = this.groupsHistory.find((e) => e.groupId == groupId);
      if (group) {
        group.groupName = groupName;
      } else {
        group = { groupId, groupName };
        this.groupsHistory.push(group);
      }
      localStorage.setItem("GROUP_IDS", JSON.stringify(this.groupsHistory));
    },

    delGroupSearchHistory(groupId = this.groupId) {
      this.groupsHistory = this.groupsHistory.filter(
        (e) => e.groupId != groupId
      );
      localStorage.setItem("GROUP_IDS", JSON.stringify(this.groupsHistory));
    },

    getGroupSearchHistory() {
      let groups = localStorage.getItem("GROUP_IDS");
      if (!groups) return;
      this.groupsHistory = JSON.parse(groups);
    },

    shareLink() {
      navigator.share({
        title: "Link to Vie Monie",
        url: this.$route.fullPath,
      });
    },

    ...mapActions(["appIsLoading", "appIsLoaded"]),
  },

  computed: {
    ...mapGetters({ isLoading: "appIsLoading" }),
  },

  watch: {
    "$route.query.id"() {
      let groupId = this.$route.query.id;
      if (groupId) {
        this.groupId = groupId;
        this.searchGroup();
      } else {
        this.group = {};
      }
    },
  },
};
</script>
