<template>
  <content-card v-if="isUserInTransaction">
    <!-- Card Header -->
    <v-card-title class="pa-0 align-baseline">
      <span class="text-h6">{{ tr.title }}</span>
      <span v-if="isShowRawCost" class="caption ml-1">#{{ trId }}</span>
      <v-spacer></v-spacer>
      <span class="ml-auto d-flex align-baseline">
        <span>{{ Number(tr.amount).toLocaleString() }} THB</span>
        <v-btn
          class="ml-3"
          small
          icon
          @click="delTransactionAndReloadTr(trId, tr.title)"
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </span>
    </v-card-title>

    <v-divider></v-divider>
    <div class="caption float-right">
      {{ $d($options.filters.todate(tr.timestamp), "med") }}
    </div>

    <!-- Payer List -->
    <div class="subtitle-2 mt-3">{{ $tc("gen.payer", tr.payers.length) }}</div>
    <div class="d-flex flex-wrap">
      <v-card
        class="py-1 px-2 ma-1 rounded-lg"
        outlined
        v-for="(payer, i) in tr.payers"
        :key="i"
        :class="{
          'success lighten-4': user == payer.payer,
        }"
      >
        <div class="font-weight-bold">
          {{ payer.payer }} +{{ payer.amount }} THB
        </div>
      </v-card>
    </div>

    <!-- Renter List -->
    <div class="subtitle-2 mt-4">
      {{ $tc("gen.renter", Object.keys(tr.rentersPaid).length) }}
    </div>
    <div class="d-flex flex-wrap">
      <v-card
        v-for="(renterPaid, renter) in tr.rentersPaid"
        :key="renter"
        outlined
        class="px-2 py-1 ma-1 renter-box lighten-4 rounded-lg"
        :class="{
          error: user == renter && !isCompletePaid(tr, renter),
          success: user == renter && isCompletePaid(tr, renter),
        }"
      >
        <div class="font-weight-bold">{{ renter }}</div>

        <v-divider class="my-1"></v-divider>

        <v-btn
          v-for="(amount, payer) in dynRentersCost[renter]"
          :key="payer"
          x-small
          block
          depressed
          class="caption mt-1"
          :class="{
            'text-decoration-line-through':
              !isShowRawCost && isRenterPaid(tr, renter, payer),
          }"
          :disabled="isDisablePayFor(renter, payer)"
          @click="setPaid(tr, renter, payer)"
        >
          {{ payer }} {{ amount.toFixed(2) }}
          <b
            v-if="
              tr.rentersPaid &&
              tr.rentersPaid[renter][payer] &&
              !isRenterPaid(tr, renter, payer)
            "
            class="success--text"
          >
            - {{ tr.rentersPaid[renter][payer].toFixed(2) }}
          </b>
          THB
        </v-btn>
        <div v-if="!Object.keys(dynRentersCost[renter]).length">-</div>
      </v-card>
    </div>

    <!-- Slip Image -->
    <v-chip
      v-if="slipImageUrl"
      small
      class="mt-4"
      :href="slipImageUrl"
      target="_blank"
    >
      <v-icon small left> mdi-file-image </v-icon>
      Slip / Receipt
    </v-chip>
  </content-card>
</template>

<script>
import ContentCard from "@/components/ContentCard.vue";

import { mapActions } from "vuex";
import Group from "@/service/group.js";
import { getLink, deleteFile } from "@/service/storage.js";

export default {
  props: {
    tr: {
      default: {},
    },
    trId: {
      default: "",
    },
    user: {
      default: "",
    },
    isShowRawCost: {
      default: false,
    },
    group: {
      type: Group,
      default: () => ({}),
    },
  },

  components: {
    ContentCard,
  },

  data: () => ({
    slipImageUrl: "",
  }),

  methods: {
    strTime(timestamp) {
      if (!timestamp || !timestamp.seconds) return "";
      return new Date(timestamp.seconds * 1000).toLocaleString("en-GB");
    },

    async delTransactionAndReloadTr(trId, title) {
      if (
        !(await this.appOpenDialog({
          title: this.$t("group.removeThisItem"),
          body: `'${title}'`,
          color: "red",
        }))
      )
        return;
      this.$emit("loading", true);
      // Delete Image
      if (this.tr.slipImageName) {
        await deleteFile(this.tr.slipImageName);
      }
      await this.group.deleteTransaction(trId);
      this.$emit("loading", false);
      this.$emit("reload");
    },

    async setPaid(tr, renter, payer) {
      let renterPaid = tr.rentersPaid[renter][payer];
      let renterCost = tr.rentersCost[renter][payer];
      let amountToPay = renterCost - renterPaid;
      let isPaying = amountToPay > 0 && (this.group.costReducingMode == 0);
      let content;
      if (isPaying) {
        content = {
          title: this.$t("group.userPayToUser", {
            user1: renter,
            user2: payer,
          }),
          body: `${this.$t("gen.for")} ${amountToPay.toFixed(2)}฿`,
        };
      } else {
        content = {
          title: this.$t("group.userUnPayToUser", {
            user1: renter,
            user2: payer,
          }),
          body: `${this.$t("gen.for")} ${renterPaid.toFixed(2)}฿`,
        };
      }
      if (!(await this.appOpenDialog(content))) return;
      this.appIsLoading();
      await this.group.setPayment(
        this.trId,
        renter,
        payer,
        isPaying ? amountToPay : 0,
        isPaying
      );
      this.appIsLoaded();
      this.$emit("reload");
    },

    isCompletePaid(tr, renter) {
      for (let payer in tr.rentersPaid[renter]) {
        if (!this.isRenterPaid(tr, renter, payer)) return false;
      }
      return true;
    },

    isRenterPaid(tr, renter, payer) {
      return tr.rentersCost[renter][payer] <= tr.rentersPaid[renter][payer];
    },

    isDisablePayFor(renter, payer) {
      return (
        this.isShowRawCost ||
        (this.group.costReducingMode != 0 && !this.tr.rentersPaid[renter][payer])
      );
    },

    ...mapActions(["appIsLoading", "appIsLoaded", "appOpenDialog"]),
  },

  computed: {
    dynRentersCost() {
      return this.isShowRawCost ? this.tr.rentersRawCost : this.tr.rentersCost;
    },

    isUserInTransaction() {
      if (!this.user) {
        return true;
      }
      return (
        !!this.tr.payers.find(({ payer }) => payer === this.user) ||
        !!this.tr.rentersPaid[this.user]
      );
    },
  },

  watch: {
    tr: {
      async handler() {
        this.slipImageUrl = this.tr.slipImageName
          ? await getLink(this.tr.slipImageName)
          : "";
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.renter-box {
  min-width: 100px;
}
</style>