<template>
  <v-card class="pa-2 rounded-lg" outlined>
    <!-- Box header -->
    <div class="pa-1" v-html="$t('group.userHasToPay', { name: renter, amount: sumAmount })">
    </div>

    <!-- Payer list for this renter -->
    <div class="d-flex flex-wrap">
      <v-btn
        v-for="(amount, payer) in renterCost"
        :key="payer"
        class="mx-2 my-1"
        small
        outlined
        :disabled="disabled"
        @click="$emit('pay', renter, payer, amount)"
      >
        {{ payer }} {{ amount.toFixed(2) }} ฿
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    renterCost: {
      default: {}
    },
    renter: {
      default: ""
    },
    disabled: {
      default: false
    }
  },

  computed: {
    sumAmount () {
      const amounts = Object.values(this.renterCost)
      const totalAmount = amounts.reduce((sum, cur) => sum + cur, 0) || 0
      return totalAmount.toFixed(2)
    }
  }
};
</script>