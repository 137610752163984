<template>
  <div>
    <!-- Group Header -->
    <content-card>
      <!-- Group Name and Id -->
      <v-card-title class="pa-0 align-baseline">
        <span class="text-h5">{{ group.name }}</span>
        <a class="caption ml-1" @click="shareLink()">#{{ group.groupId }}</a>
        <v-spacer></v-spacer>
        <v-btn
          small
          icon
          :disabled="!isGroupDeletable"
          :loading="isLoading"
          @click="delGroup()"
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </v-card-title>

      <!-- Group's Members -->
      <div class="mt-2">
        {{ $tc("gen.member") }}
        <v-chip
          small
          class="ma-1"
          :class="{ success: user == member }"
          v-for="(member, i) in group.members"
          :key="i"
          @click="selectUser(member)"
        >
          {{ member }}
        </v-chip>
        <MemberSettingDialog :group="group" @reloadGroup="reloadGroup()" />
      </div>

      <!-- Create Date -->
      <div class="mt-4 caption">
        {{ $t("gen.created") }}
        {{ $d($options.filters.todate(group.timestamp), "med") }}
      </div>

      <!-- Payment Summary -->
      <template v-if="Object.keys(transactions).length">
        <v-divider class="mt-2"></v-divider>

        <div class="mt-3 text-h6 d-flex">
          <span> {{ $t("group.moneyUsage") }} </span>
          <v-spacer />
          <span
            >{{ $t("gen.total") }}
            {{ Number(group.stat.totalAmount).toLocaleString() }} ฿</span
          >
        </div>

        <BarProportion
          class="mt-2"
          :items="payersStat"
          :select="user"
          @click="(user) => selectUser(user)"
          :title="$tc('gen.payer', 2)"
        />

        <div class="mt-6 text-h6">
          {{ $t("gen.summary") }}
          <span v-if="costReducingMode == 1" class="caption">
            ({{ $t("gen.using") }} <v-icon small>mdi-graphql</v-icon>)
          </span>
          <span v-else-if="costReducingMode == 2" class="caption">
            ({{ $t("gen.using") }} <v-icon small>mdi-bank-transfer</v-icon>)
          </span>
        </div>

        <v-alert
          v-if="
            (user && !dynRentersCost[user]) ||
            (!user && !Object.keys(dynRentersCost).length)
          "
          type="success"
          class="mt-2 mb-0 rounded-lg"
          text
        >
          <span
            v-if="user"
            v-html="$t('group.completePayBy', { name: user })"
          ></span>
          <span v-else v-html="$t('group.allCompletePayBy')"></span>
        </v-alert>

        <!-- Payment -->
        <template v-if="!user">
          <SummaryCard
            class="mt-2"
            v-for="(renterCost, renter) in dynRentersCost"
            :key="renter"
            :renterCost="renterCost"
            :renter="renter"
            :disabled="isShowRawCost"
            @pay="setPaymentPair"
          />
        </template>
        <template v-else-if="dynRentersCost[user]">
          <SummaryCard
            class="mt-2"
            :renterCost="dynRentersCost[user]"
            :renter="user"
            :disabled="isShowRawCost"
            @pay="setPaymentPair"
          />
        </template>
      </template>
    </content-card>

    <v-divider class="mt-8"></v-divider>

    <!-- Transaction Menu -->
    <div class="mt-5 d-flex align-center">
      <v-checkbox
        class="mt-0 pt-0"
        v-model="isShowRawCost"
        :label="$t('group.showRawCost')"
        dense
        hide-details
      ></v-checkbox>
      <v-spacer></v-spacer>
      <v-btn small outlined @click="isShowAddItem = true">
        <v-icon small left>mdi-plus</v-icon>{{ $t("group.addItem") }}
      </v-btn>
    </div>

    <NewTransactionCard
      class="mt-5"
      v-if="isShowAddItem"
      :members="group.members"
      :group="group"
      @done="closeAddItemAndReloadTr()"
      @cancel="isShowAddItem = false"
    />

    <TransactionCard
      v-for="(tr, trId) in transactions"
      :key="trId"
      :tr="tr"
      :trId="trId"
      :isShowRawCost="isShowRawCost"
      :user="user"
      :group="group"
      class="mt-5"
      @reload="reloadTransactions()"
    />
  </div>
</template>

<script>
import NewTransactionCard from "@/components/NewTransactionCard.vue";
import SummaryCard from "@/components/SummaryCard.vue";
import TransactionCard from "@/components/TransactionCard.vue";
import BarProportion from "@/components/BarProportion.vue";
import ContentCard from "@/components/ContentCard.vue";
import MemberSettingDialog from "@/components/MemberSettingDialog";
import MemberBankSection from "@/components/MemberBankSection.vue";

import { mapActions, mapGetters } from "vuex";
import Group from "@/service/group.js";

export default {
  props: {
    group: {
      type: Group,
      default: () => ({}),
    },
  },

  components: {
    NewTransactionCard,
    SummaryCard,
    TransactionCard,
    BarProportion,
    ContentCard,
    MemberSettingDialog,
  },

  data: () => ({
    user: "",
    isShowAddItem: false,
    isShowRawCost: false,
  }),

  methods: {
    async delGroup() {
      if (!this.isGroupDeletable) return;
      if (
        !(await this.appOpenDialog({
          title: this.$t("group.deleteThisGroup"),
          body: `'${this.group.name}'`,
          color: "red",
        }))
      )
        return;
      this.$emit("delGroupSearchHistory");
      this.appIsLoading();
      await this.group.delete();
      this.appIsLoaded();
    },

    selectUser(name) {
      if (name == this.user) name = undefined;
      this.$router.replace({ query: { ...this.$route.query, name } });
      this.user = name;
    },

    async closeAddItemAndReloadTr() {
      this.isShowAddItem = false;
      this.reloadTransactions();
    },

    async setPaymentPair(user1, user2, amount) {
      if (
        !(await this.appOpenDialog({
          title: this.$t("group.userPayToUser", { user1, user2 }),
          body: `${this.$t("gen.for")} ${amount.toFixed(2)}฿`,
          dynamicComponent: MemberBankSection,
          dynamicProps: {
            memberInfo: { ...this.group.membersInfo[user2] },
          },
        }))
      )
        return;

      this.appIsLoading();
      await this.group.setPaymentPair(user1, user2, amount);
      this.appIsLoaded();
      this.reloadTransactions();
    },

    async reloadTransactions() {
      this.$emit("reloadTransactions");
    },

    async reloadGroup() {
      this.$emit("reloadGroup");
    },

    shareLink() {
      navigator.share({
        title: "Link to Vie Monie",
        url: this.$route.fullPath,
      });
    },

    ...mapActions(["appIsLoading", "appIsLoaded", "appOpenDialog"]),
  },

  computed: {
    transactions() {
      return this.group.transactions || {};
    },

    rentersCost() {
      return this.group.rentersCost || {};
    },

    rentersRawCost() {
      return this.group.rentersRawCost || {};
    },

    isGroupDeletable() {
      return !Object.keys(this.transactions).length;
    },

    costReducingMode() {
      // 0: No reducing, 1: Using graph, 2: Using net balance
      return this.group.costReducingMode;
    },

    dynRentersCost() {
      return this.isShowRawCost ? this.rentersRawCost : this.rentersCost;
    },

    payersStat() {
      let result = [];
      for (const payer in this.group.stat.payers) {
        result.push({
          ...this.group.stat.payers[payer],
          name: payer,
        });
      }
      return result;
    },

    ...mapGetters({ isLoading: "appIsLoading" }),
  },
};
</script>

<style>
</style>