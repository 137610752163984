
import imageCompression from 'browser-image-compression';

export const compressImage = async (imageFile) => {
    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
  
    const options = {
      maxSizeMB: 0.24,
      maxWidthOrHeight: 2000,
      useWebWorker: true,
      fileType: 'image/jpeg'
    }

    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
  
      return compressedFile;
    } catch (error) {
      console.log(error);
      return null;
    }
  
  }