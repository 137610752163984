import firebase from "./firebase"
import { getDoc, getFirestore, collection, getDocs, doc, setDoc, addDoc, writeBatch, runTransaction, query, where, deleteDoc, orderBy, updateDoc } from "firebase/firestore"

const GROUPS_COL = "groups";
const TRANSACTIONS_COL = "transactions";


const db = getFirestore();
const Groups = collection(db, GROUPS_COL);

// =====================================================
//                    G R O U P
// =====================================================

export const createGroup = async (name, members = [], costReducingMode = 0) => {
	if (!name) return false;

	const docRef = await addDoc(Groups, {
		name,
		members,
		costReducingMode,
		timestamp: new Date()
	})
	console.log("Wrote at ", docRef.id);
	return docRef.id;
}

export const delGroup = async (groupId) => {
	await deleteDoc(doc(db, GROUPS_COL, groupId));
	return true;
}

export const getGroup = async (groupId) => {
	if (!groupId) return {};

	const docRef = doc(db, GROUPS_COL, groupId);
	const docSnap = await getDoc(docRef);
	if (!docSnap.exists()) return {};
	return docSnap.data();
}

export const addMembers = async (groupId, members = []) => {
	const docRef = doc(db, GROUPS_COL, groupId);
	try {
		let _members = [];
		await runTransaction(db, async (transaction) => {
			const docSnap = await transaction.get(docRef);
			if (!docSnap.exists) throw new Error();

			_members = docSnap.data().members || [];
			members.forEach((member) => {
				if (!_members.includes(member)) _members.push(member)
			});
			transaction.update(docRef, { members: _members });
		});
		return _members;
	} catch (e) {
		return false;
	}
}

// Add Payment infomation of a member.
// e.g. bankInfo: { 
// 	accountHolderName: 'John Doe',
// 	bankAccountNumber: '123-456-7890',
// 	bank: 'KBank',
// 	qrCode: 'https://example.com/qr.png'
// }
// TODO: may optimize by updating multiple members at once
export const addOrUpdateMemberInfo = async (groupId, member, bankInfo) => {
	if (!groupId || !member || !bankInfo) return false;

	try {
		// Get group doc
		const groupRef = doc(db, GROUPS_COL, groupId);

		// Using transaction to ensure that nothing change (docSnap) during the update
		await runTransaction(db, async (transaction) => {
			const docSnap = await transaction.get(groupRef);
			if (!docSnap.exists) throw new Error();

			 // Check if member exists
			const members = docSnap.data().members || [];
			if (!members.includes(member)) throw new Error();

			// Update memberInfo
			const membersInfo = docSnap.data().membersInfo || {};
			membersInfo[member] = JSON.parse(JSON.stringify(bankInfo));
			transaction.update(groupRef, { membersInfo });
		})
		return true;
	} catch (e) {
		return false;
	}
}

// =====================================================
//               T R A N S E C T I O N
// =====================================================

export const addTransaction = async (groupId, { title, amount, payers, rentersCost, rentersPaid, timestamp, slipImageName }) => {
	if (!groupId || !title || !amount || !payers || !payers.length) return false;
	const transaction = {
		title,
		amount,
		payers,
		rentersCost,
		rentersPaid,
		slipImageName,
		timestamp: timestamp || new Date(),
	}

	const groupRef = doc(db, GROUPS_COL, groupId);
	const transCols = collection(groupRef, TRANSACTIONS_COL);
	const docRef = await addDoc(transCols, transaction)
	console.log("Wrote at ", docRef.id);
	return { trId: docRef.id, transaction };
}

export const deleteTransaction = async (groupId, transactionId) => {
	const groupRef = doc(db, GROUPS_COL, groupId);
	await deleteDoc(doc(groupRef, TRANSACTIONS_COL, transactionId));
	return true;
}

export const getTransactions = async (groupId) => {
	if (!groupId) return {};

	const groupRef = doc(db, GROUPS_COL, groupId);
	const transCols = collection(groupRef, TRANSACTIONS_COL);
	const q = query(transCols);
	const querySnap = await getDocs(q);

	let temp = [];
	querySnap.forEach((doc) => {
		temp.push({ trId: doc.id, ...doc.data() });
	});

	// Sort Transaction
	function compareTimestamp(a, b) {
		if (a.timestamp > b.timestamp) return -1;
		if (a.timestamp < b.timestamp) return 1;
		return 0;
	}
	temp.sort(compareTimestamp);

	// Convert Array to Object
	let result = {};
	temp.forEach((tr) => {
		let trId = tr.trId;
		delete tr.trId;
		result[trId] = tr;
	});
	return result;
}

export const setPayment = async (groupId, transactionId, renter, payer, amount = null, isAdd = false) => {
	if (amount == null) return false;

	const groupRef = doc(db, GROUPS_COL, groupId);
	const docRef = doc(groupRef, TRANSACTIONS_COL, transactionId);
	console.log('setPayment', transactionId, { renter, payer, amount, isAdd });
	try {
		await runTransaction(db, async (transaction) => {
			const docSnap = await transaction.get(docRef);
			if (!docSnap.exists) throw new Error();

			let data = docSnap.data();
			let rentersPaid = data.rentersPaid;
			rentersPaid[renter][payer] = amount + (isAdd ? rentersPaid[renter][payer] : 0);

			transaction.update(docRef, { rentersPaid });
		});
		return true;
	} catch (e) {
		return false;
	}
};

// =====================================================
//  USE CASE EXAMPLE
// =====================================================

async function run_example1() {
	// Create a group, then add members
	let groupId = await createGroup('Group name', ['a', 'z'])
	addMembers(groupId, 'b');
	console.log(await getGroup(groupId));

	// Create a transaction with payers and rentersCost
	let { trId } = await addTransaction(groupId, {
		title: 'hi',
		amount: 20,
		payers: [{
			payer: 'a',
			amount: 20
		}],
		rentersPaid: { b: { a: 0 } },
		rentersCost: { b: { a: 20 } },
		// rentersIsPaid: { b: { a: false } }, // NOT USED
	});
	console.log(await getTransactions(groupId));

	// Add payment to a transaction, renter b paid 20 to payer a
	console.log(await setPayment(groupId, trId, "b", "a", 20));
	console.log(await getTransactions(groupId));

	// Delete a transaction
	await deleteTransaction(groupId, trId);
	console.log(await getTransactions(groupId));

	// Delete a group
	await delGroup(groupId);
}

async function run_example2() {
	// Create a group, then add members
	let groupId = await createGroup('Group name (ex2)', ['a', 'c'], true)
	addMembers(groupId, 'b');
	console.log(await getGroup(groupId));

	// Create a transaction with payers and rentersCost
	let { trId } = await addTransaction(groupId, {
		title: 'hi',
		amount: 150,
		payers: [{
			payer: 'a',
			amount: 100
		}, {
			payer: 'b',
			amount: 50
		}],
		rentersPaid: { b: { a: 0 }, c: { a: 0 } },
		rentersCost: { b: { a: 20 }, c: { a: 50 } },
		// rentersIsPaid: { b: { a: false } }, // NOT USED
	});
	console.log(await getTransactions(groupId));

	// Add payment to a transaction, renter b paid 20 to payer a
	// console.log(await setPayment(groupId, trId, "b", "a", 20));
	// console.log(await getTransactions(groupId));

	// Delete a transaction
	// await deleteTransaction(groupId, trId);
	// console.log(await getTransactions(groupId));

	// Delete a group
	// await delGroup(groupId);
}

// run_example2();

// =====================================================
//  DATABASE STRUCTURE
// =====================================================
/*

groups: {
  groupId: {
	name: string,
	members: [string],
	memberInfo: { [member: string]: {
		bank: string,
		bankAccountNumber: string,
		accountHolderName: string,
		qrCode: string
	} },
	costReducingMode: number,
	timestamp: Date

	transactions: {
	  transactionId: {
		title: string,
		amount: number,
		payers: {
		  payer: string,
		  amount: number
		}[],
		rentersCost: { [renter: string]: { [payer: string]: number } },
		rentersPaid: { [renter: string]: { [payer: string]: number } },
		slipImageName: string,
		timestamp: Date
	  }[]
	}
  }
}[]

*/