<template>
  <v-app-bar app color="primary" dark src="@/assets/pattern-bg.svg">
    <router-link
      to="/"
      class="white--text d-flex align-center text-h5"
      style="text-decoration: none"
    >
      Vie Monie <span class="ml-1 text-caption"> v1.5.1</span>
    </router-link>

    <v-spacer></v-spacer>

    <!-- Language Menu -->
    <v-menu offset-y min-width="110">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon>mdi-web</v-icon>
        </v-btn>
      </template>
      <v-list nav dense>
        <v-subheader class="justify-center"><v-icon small left>mdi-web</v-icon> {{ $t('gen.language') }}</v-subheader>
        <v-list-item-group v-model="selectedLang">
          <v-list-item
            v-for="(item, i) in langs"
            :key="i"
            @click="$i18n.locale = item.value"
            dense
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <v-btn to="/about" icon>
      <v-icon>mdi-information-outline</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    langs: [
      {
        title: "ไทย",
        value: "th",
      },
      {
        title: "English",
        value: "en",
      },
    ],
    selectedLang: 0,
  }),

  mounted() {
    this.loadLocale();
    this.updateSelectedLang();
  },

  methods: {
    updateSelectedLang() {
      this.selectedLang = this.langs.findIndex(
        (e) => e.value == this.$i18n.locale
      );
    },

    saveLocale() {
      localStorage.setItem('locale', this.$i18n.locale);
    },

    loadLocale() {
      let locale = localStorage.getItem('locale');
      if (locale) this.$i18n.locale = locale;
    }
  },

  watch: {
    "$i18n.locale"() {
      this.updateSelectedLang();
      this.saveLocale();
    },
  },
};
</script>