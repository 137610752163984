<template>
  <div v-if="!isNoInfo" class="mt-2">
    <v-divider class="my-4"></v-divider>

    <div class="mb-2 d-flex align-center">
      <div class="flex-grow-1 mr-2">Bank:</div>
      <div class="px-2 py-1 rounded grey lighten-3">{{ memberInfo.bank }}</div>
    </div>

    <div class="mb-2 d-flex align-center">
      <div class="flex-grow-1 mr-2">Name:</div>
      <div class="px-2 py-1 rounded grey lighten-3">
        {{ memberInfo.accountHolderName || '-' }}
      </div>
    </div>

    <div class="mb-2 d-flex align-center font-weight-bold black--text">
      <div class="flex-grow-1 mr-2">Acc No:</div>
      <div class="px-2 py-1 rounded grey lighten-3">
        {{ memberInfo.bankAccountNumber }}
      </div>
      <CopyButton
        className="ml-1"
        small
        icon
        :value="memberInfo.bankAccountNumber"
      />
    </div>
  </div>
</template>

<script>
import CopyButton from "@/components/CopyButton.vue";

export default {
  props: {
    memberInfo: {
      default: () => ({
        bank: "",
        bankAccountNumber: "",
        accountHolderName: "",
      }),
    },
  },

  components: {
    CopyButton,
  },

  mounted() {},

  computed: {
    isNoInfo() {
      return (
        !this.memberInfo.bank &&
        !this.memberInfo.bankAccountNumber
      );
    },
  },
};
</script>
