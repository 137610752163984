<template>
  <v-container>
    <v-form :disabled="isFormDisable">
      <v-text-field
        name="name"
        :label="$t('createGroup.groupName')"
        v-model="name"
        :rules="[(v) => !!v || 'Name is required']"
      ></v-text-field>

      <div class="flex">
        <div class="mt-6 mb-3 title gap-2">Summary Cost Reducing Mode</div>
        <v-item-group v-model="costReducingMode" mandatory active-class="primary">
          <v-item v-slot="{ active, toggle }" :value="0">
            <v-chip small class="mr-1 mb-1" @click="toggle">Basic (Reciprocal Reduction)</v-chip>
          </v-item>
          <v-item v-slot="{ active, toggle }" :value="1">
            <v-chip small class="mr-1 mb-1" @click="toggle">Graph Topology</v-chip>
          </v-item>
          <v-item v-slot="{ active, toggle }" :value="2">
            <v-chip small class="mr-1 mb-1" @click="toggle">Net Balances (Best)</v-chip>
          </v-item>
        </v-item-group>
        <div class="caption">
          For reducing summary money, more on: 
          <router-link to="/about#basic-reducer">Basic Mode</router-link>,
          <router-link to="/about#graph-topology-reducer">Graph Topology Mode</router-link>,
          <router-link to="/about#net-balance-reducer">Net Balances Mode</router-link>
        </div>
      </div>

      <div class="mt-6 title">{{ $tc('gen.member', members.length) }}</div>

      <div class="py-1" v-for="(member, i) in members" :key="i">
        <span>{{ i + 1 }}. {{ member }}</span>
        <v-btn
          v-if="!isFormDisable && !isLoading"
          class="ml-3"
          x-small
          outlined
          color="red"
          @click="delMember(i)"
          >{{ $t('gen.delete') }}</v-btn
        >
      </div>
      <v-row v-if="!isFormDisable" class="align-center" no-gutters>
        <v-text-field
          class="mr-3"
          name="name"
          :label="$t('gen.nickname')"
          v-model="member"
          @keydown.enter="addMember()"
        ></v-text-field>
        <v-btn outlined @click="addMember()" :disabled="isLoading || !isMemberAddable"
          >{{ $t('gen.add') }}</v-btn
        >
      </v-row>

      <div class="mt-3 text-center">
        <v-btn
          v-if="!isFormDisable"
          :disabled="!isFormOK"
          :loading="isLoading"
          color="success"
          @click="createGroup()"
          >{{ $t('gen.create') }}</v-btn
        >
      </div>
    </v-form>

    <!-- Success Alert -->
    <div v-if="groupId" class="mt-12 mx-auto" style="max-width: 500px">
      <v-alert type="success" text>
        <span>{{ $t('createGroup.yourGroupId') }} </span>
        <b>
          <a :href="`/?id=${groupId}`">#{{ groupId }}</a>
        </b>
      </v-alert>

      <div class="mt-4 text-center">
        <v-card outlined class="pa-3 d-inline-block">
          <div>
            <img
              :src="`https://api.qrserver.com/v1/create-qr-code/?size=180x180&data=${sharableUrl}`"
              alt="Shareable QR Code"
            />
          </div>
          <div class="d-flex justify-center align-center">
            <v-icon small left>mdi-share-variant</v-icon>
            <a @click="shareLink()">{{ $t('createGroup.shareThis') }}</a>
          </div>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import Group from "@/service/group.js";
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    name: "",
    members: [],
    member: "",
    groupId: "",
    isFormDisable: false,
    costReducingMode: 2 // 0: Basic, 1: Graph, 2: Net Balances
  }),

  methods: {
    addMember() {
      if (!this.isMemberAddable) return;
      this.members.push(this.member);
      this.member = "";
    },
    delMember(i) {
      this.members.splice(i, 1);
    },

    async createGroup() {
      let group = new Group();
      this.appIsLoading();
      await group.create(this.name, this.members, this.costReducingMode);
      this.appIsLoaded();
      this.groupId = group.groupId;
      if (!this.groupId) return;
      this.isFormDisable = true;
      this.saveGroupSearchHistoryV2(this.groupId, this.name);
    },

    saveGroupSearchHistoryV2(groupId, groupName) {
      let groups = localStorage.getItem("GROUP_IDS");
      if (!groups) return;
      groups = JSON.parse(groups);

      if (groups.find((e) => e.groupId == groupId)) return;
      let group = { groupId, groupName };
      groups.push(group);
      localStorage.setItem("GROUP_IDS", JSON.stringify(groups));
    },

    shareLink() {
      navigator.share({
        title: "Link to Vie Monie",
        url: this.sharableUrl,
      });
    },

    ...mapActions(["appIsLoading", "appIsLoaded"]),
  },

  computed: {
    isFormOK() {
      return !!this.name && !!this.members.length;
    },

    isMemberAddable() {
      return this.member && !this.members.includes(this.member);
    },

    sharableUrl() {
      return `${window.location.origin}/?id=${this.groupId}`;
    },

    ...mapGetters({ isLoading: "appIsLoading" }),
  },
};
</script>

<style>
</style>