<template>
  <v-dialog v-model="dialog" width="300">
    <template v-slot:activator="{}">
      <v-btn small icon @click="openDialog()">
        <v-icon small>mdi-cog</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title> Member Setting </v-card-title>

      <v-card-text>
        <div>
          <v-chip
            small
            class="ma-1"
            :class="{ success: user == member }"
            v-for="(member, i) in group.members"
            :key="i"
            @click="selectUser(member)"
            :disabled="member != user && isUserInfoChange"
          >
            {{ member }}
          </v-chip>
        </div>

        <v-divider class="my-3"></v-divider>

        <div v-if="!!user && newMembersInfo[user]">
          <v-combobox
            label="Bank*"
            dense
            class="mt-4"
            outlined
            :items="items"
            hide-details
            v-model="newMembersInfo[user].bank"
          ></v-combobox>

          <v-text-field
            label="Account Holder Name"
            class="mt-3"
            dense
            outlined
            hide-details
            v-model="newMembersInfo[user].accountHolderName"
          ></v-text-field>

          <v-text-field
            label="Bank Account*"
            class="mt-3"
            dense
            outlined
            hide-details
            v-model="newMembersInfo[user].bankAccountNumber"
          ></v-text-field>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" small text @click="closeDialog()">
          {{ $t("gen.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          small
          text
          @click="saveMembersInfo()"
          :disabled="isUserInfoChange && !isSavable"
        >
          {{ $t("gen.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Group from "@/service/group.js";
import bankList from "@/assets/bank-list.json";
import { mapActions } from "vuex";

const originalMembersInfo = {
  bank: null,
  bankAccountNumber: "",
  accountHolderName: "",
};

export default {
  props: {
    group: {
      type: Group,
      default: () => ({}),
    },
  },

  data() {
    return {
      dialog: false,
      user: undefined,
      newMembersInfo: {},
      items: bankList.map((bank) => `${bank.text} (${bank.value})`),
    };
  },

  mounted() {
    this.user = this.group.members[0] || undefined;
  },

  methods: {
    selectUser(name) {
      this.user = name;
    },

    openDialog() {
      this.dialog = true;

      // Prepare newMembersInfo
      const members = this.group.members;
      const newMembersInfo = { ...this.group.membersInfo };
      members.forEach((member) => {
        newMembersInfo[member] = {
          ...originalMembersInfo,
          ...newMembersInfo[member],
        };
      });
      this.newMembersInfo = newMembersInfo;
    },

    closeDialog() {
      this.dialog = false;
    },

    async saveMembersInfo() {
      this.closeDialog();

      // If not savable, return
      if (!this.isUserInfoChange) {
        return;
      }

      // Update only the current user
      this.appIsLoading();
      const memberInfo = this.newMembersInfo[this.user];
      await this.group.addOrUpdateMemberInfo(this.user, memberInfo);
      this.appIsLoaded();

      // Reload group
      await new Promise((resolve) => setTimeout(resolve, 100));
      this.$emit("reloadGroup");
    },

    ...mapActions(["appIsLoading", "appIsLoaded", "appOpenDialog"]),
  },

  computed: {
    isUserInfoChange() {
      if (
        !this.user ||
        !this.newMembersInfo[this.user]
      ) {
        return false;
      }

      function isChange(obj1, obj2) {
        // Check if both arguments are objects
        if (
          typeof obj1 !== "object" ||
          typeof obj2 !== "object" ||
          obj1 === null ||
          obj2 === null
        ) {
          throw new Error("Both arguments must be non-null objects");
          // return true;
        }

        // Iterate over keys in obj1 and compare values
        for (let key in obj1) {
          if (obj1.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
            return true; // Difference found
          }
        }

        // Iterate over keys in obj2 to check for extra keys
        for (let key in obj2) {
          if (obj2.hasOwnProperty(key) && !(key in obj1)) {
            return true; // Extra key found
          }
        }

        return false; // No differences found
      }

      return isChange(
        this.group.membersInfo[this.user] || { ...originalMembersInfo },
        this.newMembersInfo[this.user]
      );
    },

    isSavable() {
      // Check if there bank and bankAccountNumber are not empty
      // But if both are empty, it is considered savable
      return (
        (this.newMembersInfo[this.user].bank &&
          this.newMembersInfo[this.user].bankAccountNumber) ||
        (!this.newMembersInfo[this.user].bank &&
          !this.newMembersInfo[this.user].bankAccountNumber)
      );
    },
  },
};
</script>