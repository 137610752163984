<template>
  <v-dialog v-model="dialog" width="300">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text >
        <div v-if="body" v-html="body"></div>
        <component v-if="dynamicComponent" :is="dynamicComponent" v-bind="dynamicProps"></component>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" small text @click="cancel()">{{
          $t("gen.cancel")
        }}</v-btn>
        <v-btn :color="themeColor" small text @click="confirm()">
          {{ $t("gen.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    dialog: false,
    title: "",
    body: "",
    dynamicComponent: null,
    dynamicProps: {},
    themeColor: "primary",
  }),

  methods: {
    confirm() {
      this.appDialogCallback(true);
    },
    cancel() {
      this.appDialogCallback(false);
    },
  },

  computed: {
    ...mapGetters(["appDialogCallback", "appDialogIsInUse", "appDialogBody"]),
  },

  watch: {
    appDialogIsInUse(isInUse) {
      this.dialog = isInUse;
    },
    dialog(n, o) {
      // Close by not clicking the button, (click outside the prompt)
      if (n === false && o === true && this.appDialogIsInUse === true) {
        this.cancel();
      }
    },
    appDialogBody({ title, body, color, dynamicComponent, dynamicProps }) {
      this.title = title || "Confirm ?";
      this.body = body;
      this.themeColor = color || "primary";
      this.dynamicComponent = dynamicComponent;
      this.dynamicProps = dynamicProps || {};
    },
  },
};
</script>

<style>
</style>