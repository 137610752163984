<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-btn
          class="!px-2"
          :class="className"
          text
          v-bind="$attrs"
          :icon="icon"
          @click="copyToClipboard(value)"
        >
          <v-icon v-bind="$attrs" :left="!icon">mdi-content-copy</v-icon>
          <span v-if="!icon">
            {{ text }}
          </span>
        </v-btn>
      </span>
    </template>

    <!-- Popup -->
    <div>
      <div class="text-center">{{ isCopy ? "Copied!" : "Click to Copy" }}</div>
      <div>
        {{ hint }}
      </div>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: "Copy",
    },
    className: {
      type: String,
      default: "",
    },
    icon: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      isCopy: false,
    }
  },
  methods: {
    copyToClipboard(value) {
      navigator.clipboard.writeText(value)
      this.isCopy = true
      setTimeout(() => {
        this.isCopy = false
      }, 5000)
    },
  },
}
</script>
