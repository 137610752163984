<template>
  <content-card>
    <!-- Item info -->
    <v-row>
      <v-col cols="8">
        <v-text-field
          v-model="title"
          :label="$t('group.itemName')"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          type="number"
          v-model="amount"
          :label="$t('group.cost')"
          suffix="THB"
          class="mb-n5"
          :rules="[rules.positive]"
          :error-messages="amountErrorMessages"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Payers List -->
    <v-row class="align-center" v-for="(_, i) in payers" :key="i">
      <!-- Member Select -->
      <v-col>
        <v-select
          hide-details
          :items="members"
          :label="$tc('gen.payer')"
          v-model="payers[i].payer"
          disabled
        ></v-select>
      </v-col>

      <!-- Pay Amount -->
      <v-col>
        <v-text-field
          hide-details
          type="number"
          :label="$t('group.amount')"
          suffix="THB"
          v-model="payers[i].amount"
          :rules="[rules.positive]"
        ></v-text-field>
      </v-col>

      <v-col class="flex-grow-0 flex-shrink-1">
        <v-btn small icon @click="delPayer(i)">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- New Payer Field -->
    <v-row class="align-center" v-if="amountLeft > 0 || payers.length === 0">
      <!-- Member Select -->
      <v-col>
        <v-select
          hide-details
          :items="selectableMembers"
          :label="$tc('gen.payer')"
          v-model="payer.payer"
          @change="addPayer()"
        ></v-select>
      </v-col>

      <!-- Pay Amount -->
      <v-col>
        <v-text-field
          hide-details
          type="number"
          :label="`${$t('group.amount')}`"
          :placeholder="String(amountLeft)"
          persistent-placeholder
          suffix="THB"
          v-model="payer.amount"
          @keydown.enter="addPayer()"
          :rules="[rules.positive]"
        ></v-text-field>
      </v-col>

      <v-col class="flex-grow-0 flex-shrink-1">
        <v-btn small icon color="info" @click="addPayer()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Choose Renters -->
    <v-select
      class="mt-8"
      :label="$tc('gen.renter', renters.length)"
      v-model="renters"
      :items="members"
      outlined
      small-chips
      multiple
      hide-details
    ></v-select>

    <!-- Custom Cost for Renters -->
    <div class="mt-5 d-flex align-baseline">
      <v-checkbox
        :disabled="!onlyPayer"
        v-model="isUseCustomSplit"
        @change="setDefaultCustomSplit()"
        :label="$t('group.useCustomSplit')"
        :hide-details="!!onlyPayer"
        persistent-hint
        :hint="onlyPayer ? '' : `(${$t('group.onlyAvailableForOnePayer')})`"
        class="mt-0"
      ></v-checkbox>
      <v-spacer></v-spacer>
      <v-chip v-if="!isUseCustomSplit" color="success" small>
        {{ $t("group.equalSplitOn") }}
      </v-chip>
    </div>

    <div
      v-if="renters.length && isUseCustomSplit && onlyPayer"
      class="mt-5 px-3 pt-3 pb-5 grey lighten-4 rounded-lg"
    >
      <div class="text-subtitle-1">{{ $t("group.customSplitForRenters") }}</div>
      <v-row v-for="(renter, i) in renters" :key="`cc-${i}`">
        <v-col cols="12">
          <v-text-field
            type="number"
            v-model="customRentersCost[renter]"
            :label="`${renter} ${$t('group.cost')}`"
            suffix="THB"
            hide-details
            persistent-placeholder
            :placeholder="String(averageRemainRentersCost)"
            @keydown.enter="
              customRentersCost[renter] = averageRemainRentersCost
            "
          ></v-text-field>
        </v-col>
      </v-row>

      <div
        v-if="totalRemainRentersCost != 0"
        class="mt-5 text-subtitle-2 red--text"
      >
        {{ $t("gen.error") }}: The amount is
        <b>
          {{ Math.abs(totalRemainRentersCost).toFixed(4) }}
          {{ totalRemainRentersCost > 0 ? "less" : "more" }}
        </b>
        than the cost.
      </div>
    </div>

    <!-- Slip Image Upload -->
    <v-divider class="mt-5"></v-divider>
    <v-file-input
      class="mt-5"
      label="Slip / Receipt (Optional)"
      outlined
      hide-details
      accept="image/png, image/jpeg"
      v-model="file"
    ></v-file-input>

    <div class="text-right mt-5">
      <v-btn color="mr-3" @click="$emit('cancel')">{{
        $t("gen.cancel")
      }}</v-btn>
      <v-btn color="success" @click="addTransaction()" :disabled="!isAddAble">{{
        $t("gen.add")
      }}</v-btn>
    </div>
  </content-card>
</template>

<script>
import ContentCard from "@/components/ContentCard.vue";

import { mapActions } from "vuex";
import Group from "@/service/group.js";
import { uploadFile } from "@/service/storage.js";
import { compressImage } from "@/service/image.js";

export default {
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Group,
      default: () => ({}),
    },
  },

  components: {
    ContentCard,
  },

  data: () => ({
    title: "",
    amount: null,
    payer: { payer: "", amount: null },
    payers: [],
    renters: [],
    file: null,

    rules: {
      number: (v) => !isNaN(parseFloat(v)) || "Must be a number",
      positive: (v) => v > 0 || "Must be more than 0",
    },

    customRentersCost: {},
    isUseCustomSplit: false,
  }),

  created() {
    this.renters = this.members;
  },

  methods: {
    addPayer() {
      if (!this.payer.payer) return;
      if (!this.payer.amount) this.payer.amount = this.amountLeft;
      this.payer.amount = parseFloat(this.payer.amount);
      this.payers.push(this.payer);
      this.payer = { payer: "", amount: null };
    },

    delPayer(i) {
      this.payers.splice(i, 1);
    },

    async uploadFile() {
      const file = this.file;
      // compress image
      const compressed = await compressImage(file);
      let res = await uploadFile(compressed);
      const fileName = res.fileName;
      return fileName;
    },

    async addTransaction() {
      if (!this.isAddAble) return;

      this.appIsLoading();
      // Upload file
      let fileName = this.file ? await this.uploadFile() : null;

      let isSuccess = await this.group.addTransaction({
        title: this.title,
        amount: this.amount,
        payers: this.payers,
        renters: this.renters,
        slipImageName: fileName,
        rentersCost: this.isUseCustomSplit ? this.virtualRentersCost : null,
      });
      this.appIsLoaded();
      if (!isSuccess) {
        return console.error("Can't add a transaction!");
      }

      this.title = "";
      this.amount = null;
      this.payers = [];
      this.$emit("done");
    },

    setDefaultCustomSplit() {
      // Set default cost for renters
      const defaultCost = this.amount / this.renters.length;
      const customRentersCost = {};
      this.renters.forEach((renter) => {
        customRentersCost[renter] = defaultCost;
      });
      this.customRentersCost = customRentersCost;
    },

    ...mapActions(["appIsLoading", "appIsLoaded"]),
  },

  computed: {
    selectableMembers() {
      let payers = this.payers.map((e) => e.payer);
      let selectable = this.members.filter((e) => !payers.includes(e));
      return selectable || [];
    },

    amountLeft() {
      const sum = this.payers.reduce(
        (s, e) => s + (parseFloat(e.amount) || 0),
        0
      );
      const amountLeft = this.amount - sum;
      return amountLeft; // > 0 ? amountLeft : 0;
    },

    amountErrorMessages() {
      if (this.payers.length === 0) {
        return;
      }

      const amountLeft = this.amountLeft;
      if (amountLeft < 0) {
        return this.$t("gen.error") + `: The amount is ${-amountLeft} less than the payers cost.`;
      }
      if (amountLeft > 0) {
        return this.$t("gen.error") + `: The amount is ${amountLeft} more than the payers cost.`;
      }
      return "";
    },

    isAddAble() {
      return (
        this.title &&
        this.amount &&
        this.payers.length &&
        this.renters.length &&
        this.amountLeft === 0 &&
        (!this.isUseCustomSplit || this.totalRemainRentersCost === 0)
      );
    },

    // ======= Custom Split for Renters =======
    onlyPayer() {
      // Return only payer in the transaction if there is only one payer
      if (this.payers.length === 1) return this.payers[0].payer;
      return null;
    },

    totalRemainRentersCost() {
      const totalRentersCost = this.renters.reduce(
        (s, r) => s + (parseFloat(this.customRentersCost[r]) || 0),
        0
      );
      const totalRemainRentersCost = this.amount - totalRentersCost;

      // Round small number to 0
      if (Math.abs(totalRemainRentersCost) < 0.00001) {
        return 0;
      }

      return totalRemainRentersCost;
    },

    averageRemainRentersCost() {
      const numberOfEmptyCustomCost = this.renters.filter(
        (r) => !this.customRentersCost[r]
      ).length;
      const averageRemainRentersCost =
        this.totalRemainRentersCost / numberOfEmptyCustomCost;
      return averageRemainRentersCost > 0 ? averageRemainRentersCost : 0;
    },

    // To format rentersCost to match the format of the transaction
    // and filter out the renter who has 0 cost
    virtualRentersCost() {
      const rentersCost = {};
      this.renters.forEach((renter) => {
        const renterCost = parseFloat(this.customRentersCost[renter]) || 0;

        if (renterCost === 0) return;

        rentersCost[renter] =
          renter == this.onlyPayer
            ? {}
            : { [this.onlyPayer]: renterCost };
      });
      return rentersCost;
    },
  },

  watch: {
    members(v) {
      this.renters = v;
    },
  },
};
</script>