import {
	addMembers,
	addOrUpdateMemberInfo,
	addTransaction,
	createGroup,
	delGroup,
	deleteTransaction,
	getGroup,
	getTransactions,
	setPayment
} from "./firestore.js";

import {
	calcCostReducerBasic,
	calcCostReducerByGraph,
	calcRentersRawCost,
	genRenterIsPaid,
	genRenterPaid,
	removeNullOrSmallField,
	calcSummarisedRentersRawCostToPay,
	calcSummarisedStat,
	calcCostReducerByNetBalance
} from "./calculator.js";

class Group {
	constructor(groupId) {
		this.groupId = groupId;
		this.name = "";
		this.members = [];
		this.timestamp = null;
		this.costReducingMode = 0; // 0: Basic, 1: Graph, 2: NetBalance

		this.transactions = {};
		this.rentersCost = {};
		this.rentersRawCost = {};
		this.stat = {}
	}

	async create(name, members = [], costReducingMode = false) {
		if (!name) throw new Error("Group needs its name");
		this.name = name;
		this.members = members;
		this.costReducingMode = costReducingMode;
		this.groupId = await createGroup(name, members, costReducingMode);
		return this.groupId;
	}

	async delete() {
		if (Object.keys(this.transactions).length) return false;
		await delGroup(this.groupId);
		this.groupId = "";
		this.name = "";
	}

	async getInfo() {
		let group = await getGroup(this.groupId);
		this.name = group.name || "";
		this.members = group.members || [];
		this.membersInfo = group.membersInfo || {};
		this.costReducingMode = group.costReducingMode || (group.isUsingGraph ? 1 : 0); // fallback compatibility for old data (.isUsingGraph)
		this.timestamp = group.timestamp;
		return group;
	}

	async addMembers(members = []) {
		this.members = await addMembers(this.groupId, members);
	}

	async addOrUpdateMemberInfo(member, info) {
		await addOrUpdateMemberInfo(this.groupId, member, info);
	}

	/**
	 * Add a transaction to the group
	 * @param {String} title
	 * @param {Number} amount
	 * @param {Object[]} payers // [{ payer: String, amount: Number }]
	 * @param {String[]} renters
	 * @param {Object} rentersCost // { [renter]: { [payer]: Number } }
	 * @param {String} slipImageName
	 * @returns {Any}
	 */
	async addTransaction({ title, amount, payers, renters, slipImageName, rentersCost }) {
		if (!rentersCost) {
			// Equal Split Cost
			let rentersRawCost = calcRentersRawCost(renters, payers);
			rentersCost = calcCostReducerBasic(rentersRawCost);
		}
		// let rentersIsPaid = genRenterIsPaid(rentersCost);
		let rentersPaid = genRenterPaid(rentersCost);
		let transaction = {
			title: title,
			amount: amount,
			payers: payers,
			rentersCost,
			// rentersIsPaid,
			rentersPaid,
			slipImageName
		};
		let res = await addTransaction(this.groupId, transaction);
		if (!res) return false;
		this.transactions = { [res.trId]: res.transaction, ...this.transactions };
		return res;
	}

	async deleteTransaction(trId) {
		await deleteTransaction(this.groupId, trId);
		delete this.transactions[trId];
		return true;
	}

	async getTransactions() {
		let transactions = await getTransactions(this.groupId);
		this.transactions = transactions;
		return transactions;
	}

	/**
	 * Set payment from renter to payer
	 * If isAdd is true, add the amount to the existing payment
	 * If isAdd is false, set the amount to the existing payment
	 * @param {String} trId 
	 * @param {String} renter 
	 * @param {String} payer 
	 * @param {Number} amount 
	 * @param {Boolean | null} isAdd 
	 * @returns 
	 * @example
	 * group.setPayment('5f7e4b4b9b3b4b0008b3b4b0', 'a', 'b', 20);
	 */
	async setPayment(trId, renter, payer, amount, isAdd) {
		return await setPayment(this.groupId, trId, renter, payer, amount, isAdd);
	}

	/**
	 * Set payment from renter to payer for all transactions
	 * If amount is not provided, set the amount to the outstanding payment
	 * @param {String} renter
	 * @param {String} payer
	 * @param {Number} amount
	 * @returns {Boolean}
	 * @example
	 * group.setPaymentPair('a', 'b', 20);
	 * group.setPaymentPair('a', 'b');
	**/
	async setPaymentPair(renter, payer, amount = this.rentersCost[renter][payer]) {
		if (this.costReducingMode == 1 || this.costReducingMode == 2) {
			// Using Graph or NetBalance Calc
			// Loop through all transactions and
			// - If that transaction has the renter - payer pair and the amount is higher than the cost, mark the payment
			// - Else If no more cost to pay, mark the payment with the remaining amount, and break the loop
			// Note: amount is the total amount that renter is going to pay
			for (let trId in this.transactions) {
				let tr = this.transactions[trId];
				if (!tr.rentersCost[renter] || !tr.rentersCost[renter][payer]) continue;
				let cost = tr.rentersCost[renter][payer];
				if (amount >= cost) {
					await this.setPayment(trId, renter, payer, cost);
				} else if (amount > 0) {
					await this.setPayment(trId, renter, payer, amount);
					break;
				}
				amount -= cost;
			}
		} else {
			// Using Basic Calc
			// Loop through all transactions and mark the payment for all pairs of renter - payer
			for (let trId in this.transactions) {
				let tr = this.transactions[trId];
				if (tr.rentersCost[renter] && tr.rentersCost[renter][payer]) {
					await this.setPayment(
						trId,
						renter,
						payer,
						tr.rentersCost[renter][payer]
					);
				} else if (tr.rentersCost[payer] && tr.rentersCost[payer][renter]) {
					await this.setPayment(
						trId,
						payer,
						renter,
						tr.rentersCost[payer][renter]
					);
				}
			}
		}
		return true;
	}

	async deleteAllTransactions() {
		for (let trId in this.transactions) {
			await this.deleteTransaction(trId);
		}
		return true;
	}

	// ===================================================
	//                  Helper Function
	// ===================================================
	sortMembers() {
		this.members.sort((a, b) => a.localeCompare(b));
	}

	// Sort Renters and Payers in each transaction by name (alphabetical order) for consistency
	sortRentersAndPayersEachTransaction() {
		for (let trId in this.transactions) {
			let tr = this.transactions[trId];
			// Sort Renters (rentersPaid)
			tr.rentersPaid = Object.keys(tr.rentersPaid)
				.sort()
				.reduce((obj, key) => {
					obj[key] = tr.rentersPaid[key];
					return obj;
				}, {});
			// Sort Payer
			tr.payers.sort((a, b) => a.payer.localeCompare(b.payer));
		}
	}

	// Calculate the raw cost for each renters in each transaction
	computeRentersRawCostEachTr() {
		for (let trId in this.transactions) {
			let tr = this.transactions[trId];
			// TODO: May have to change, this raw cost recalculates the cost for each renters using equal split
			// which may not be the same as the original cost (if custom split is used)
			/*
			tr.rentersRawCost = calcRentersRawCost(
				Object.keys(tr.rentersPaid),
				tr.payers
			);
			*/

			// To TEMP fix the above issue, skip the calculation if payers == 1
			if (tr.payers.length == 1) {
				tr.rentersRawCost = tr.rentersCost;
			} else {
				tr.rentersRawCost = calcRentersRawCost(
					Object.keys(tr.rentersPaid),
					tr.payers
				);
			}
		}
	}

	computeSummary() {
		// Summary renters in each transactions that has to pay
		let rentersRawCost = calcSummarisedRentersRawCostToPay(this.transactions)
		this.rentersRawCost = rentersRawCost;

		// Calculate Net Cost
		let rentersCost;
		if (this.costReducingMode == 0) {
			rentersCost = calcCostReducerBasic(rentersRawCost);
		} else if (this.costReducingMode == 1) {
			rentersCost = calcCostReducerByGraph(rentersRawCost);
		} else if (this.costReducingMode == 2) {
			rentersCost = calcCostReducerByNetBalance(rentersRawCost);
		}

		// Remove null field or too small value
		removeNullOrSmallField(rentersCost);
		this.rentersCost = rentersCost;

		this.stat = calcSummarisedStat(this.transactions)
	}
}

async function main() {
	let myGroup = new Group();
	await myGroup.create('hat yai', ['a', 'b']);
	console.log(myGroup);
	await myGroup.addTransaction({
		title: 'foods',
		amount: 20,
		payers: [{ payer: "a", amount: 20 }],
		renters: ["b"]
	});

	await myGroup.addTransaction({
		title: 'cake',
		amount: 120,
		payers: [{ payer: "a", amount: 120 }],
		renters: ["a", "b"]
	});
	console.log(myGroup);


	// ex 2
	// let group = new Group('yarAuAYUUE0jHRmTcwHR');
	// await group.getInfo();
	// group.sortMembers();

	// await group.getTransactions();
	// group.sortRentersAndPayersEachTransaction();
	// group.computeRentersRawCostEachTr();
	// group.computeSummary();
	// console.log(group);

	// await group.deleteAllTransactions();
}

// main();

export default Group;